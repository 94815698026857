<template>
  <v-container class="services-modify" fluid>
    <BaseLoader v-if="isLoading" />
    <template v-else>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-10">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </template>
  </v-container>
</template>

<script>
// Models
import { schema } from '@/schemas/service.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';
import { extractParamsFromMultiSelectObject } from '@/utils/many';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import BaseLoader from '@/components/BaseLoader.vue';

// Constants
import { SERVICES, SERVICES_DETAILED } from '@/constants/routes';

// Services
import servicesService from '@/services/services';
import notificationService from '@/services/notification';

import analyticsService from '@/services/analytics';
import { CREATE_SERVICE, EDIT_SERVICE } from '@/constants/analyticsActions';
import mediaService from '@/services/media';

import TYPE_SCRIPTS from '@/constants/servisesTypeScript';

export default {
  name: 'ServiceModify',

  components: { FormBuilder, EntityModifyHeader, BaseLoader },
  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    category: { type: Number, default: null },
    categoryName: { type: String, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    title() {
      return this.isEdit ? this.$t('services.edit_services') : this.$t('services.add_service');
    },
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_services') : this.$t('button.add_services');
    },
    backRoute() {
      return { name: SERVICES, query: { page: this.prevPage } };
    },
  },

  created() {
    if (this.categoryName && this.category) {
      this.initialData.category = {
        id: this.category,
        name: this.categoryName,
      };
    }
  },

  mounted() {
    if (this.isEdit) {
      this.initData();
    }
  },

  methods: {
    async initData() {
      this.isLoading = true;
      try {
        const service = await servicesService.getServicesById(this.id);
        const imageForList = [{ id: 1, selected: true, url: service.imageForList }];
        const imageForRetrieve = [{ id: 1, selected: true, url: service.imageForRetrieve }];
        const scripts = await this.formatScriptsForInitData(service.scripts);
        this.initialData = {
          ...service,
          imageForList,
          imageForRetrieve,
          scripts,
        };
      } finally {
        this.isLoading = false;
      }
    },
    async formatScriptsForInitData(scripts) {
      const formatScripts = scripts.map(script => {
        return {
          id: script.id,
          type: script.type,
          nameLink: script.type === TYPE_SCRIPTS.LINK ? script.name : '',
          actionLink: script.type === TYPE_SCRIPTS.LINK ? script.action : '',
          actionMessage: script.type === TYPE_SCRIPTS.MESSAGE ? script.action : '',
          nameMessage: script.type === TYPE_SCRIPTS.MESSAGE ? script.name : '',
          nameFile: script.type === TYPE_SCRIPTS.FILE ? script.name : '',
          actionFile: script.type === TYPE_SCRIPTS.FILE ? [{ id: 1, selected: true, url: script.action }] : [],
        };
      });
      const formatScriptsWithFile = await Promise.all(
        formatScripts.map(async item => {
          if (item.actionFile.length) {
            const actionFile = await this.formatMedia(item.actionFile);
            return {
              ...item,
              actionFile,
            };
          }
          return item;
        })
      );

      return formatScriptsWithFile;
    },
    async formatMedia(media) {
      const newMedia = await Promise.all(
        media.map(async file => {
          const newFile = { ...file };
          newFile.name = decodeURIComponent(newFile.url)
            .split('/')
            .pop();

          const { url, blob } = await this.getPrivateMediaObject(newFile.url);

          newFile.url = url;
          newFile.type = blob.type;
          newFile.size = blob.size;
          return newFile;
        })
      );
      return newMedia;
    },
    getPrivateMediaObject(url) {
      return mediaService.getPrivateMediaObject(getBaseUrlWithoutApi() + url);
    },
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }
      const bodyRequest = {
        name: data.name,
        shortDescription: data.shortDescription,
        description: data.description,
        initialAmount: data.initialAmount,
        contractor: data.contractor.id,
        order: data.order,
        publicAccess: data.publicAccess,
        category: data.category?.id || null,
        imageForList: this.extractFile(data.imageForList),
        imageForRetrieve: this.extractFile(data.imageForRetrieve),
        scripts: this.formatScripts(data.scripts),
        accessibleForProjects: extractParamsFromMultiSelectObject(data.accessibleForProjects),
      };
      this.isUpload = true;

      const saveService = this.isEdit ? servicesService.updateServiceById : servicesService.createService;
      try {
        const service = await saveService({ ...bodyRequest, serviceId: this.id });
        analyticsService.track(this.isEdit ? EDIT_SERVICE : CREATE_SERVICE);
        notificationService.success(this.isEdit ? this.$t('services.edited') : this.$t('services.created'), 2000);
        this.$router.push({ name: SERVICES_DETAILED, params: { id: service.id || this.id } });
      } catch (error) {
        this.$options.notificationItem = notificationService.error(
          `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
        );
      } finally {
        this.isUpload = false;
      }
    },

    formatScripts(scripts) {
      const typeLinks = [];
      const typeFiles = [];
      const typeMessage = [];

      scripts.forEach(script => {
        if (script.type === TYPE_SCRIPTS.LINK) {
          typeLinks.push(script);
        }
        if (script.type === TYPE_SCRIPTS.FILE) {
          typeFiles.push(script);
        }
        if (script.type === TYPE_SCRIPTS.MESSAGE) {
          typeMessage.push(script);
        }
      });

      const newScripts = [
        ...this.formatScriptsTypeLinks(typeLinks),
        ...this.formatScriptsTypeFile(typeFiles),
        ...this.formatScriptsTypeMessage(typeMessage),
      ];
      return newScripts;
    },

    extractFile(files) {
      if (files.length) {
        const selectFiles = files.find(file => file.selected);
        return selectFiles.backgroundFileId;
      }
      return 0;
    },

    formatScriptsTypeLinks(typeLinks) {
      if (typeLinks.length) {
        return typeLinks.map(script => ({
          type: script.type,
          name: script.nameLink,
          action: script.actionLink,
        }));
      }
      return [];
    },
    formatScriptsTypeFile(typeFiles) {
      if (typeFiles.length) {
        return typeFiles.map(script => ({
          type: script.type,
          name: script.nameFile,
          action: this.extractFile(script.actionFile),
          // Для того чтобы можно было редактировать.
          // id отправляем если поле action нет
          id: this.extractFile(script.actionFile) ? undefined : script.id,
        }));
      }
      return [];
    },
    formatScriptsTypeMessage(typeMessage) {
      if (typeMessage.length) {
        return typeMessage.map(script => ({
          type: script.type,
          name: script.nameMessage,
          action: script.actionMessage,
        }));
      }
      return [];
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.services-modify {
  max-width: 548px !important;
}
</style>
